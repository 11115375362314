if (navigator.serviceWorker) {
  let port = location.port,
      version = document.documentElement.dataset.version;;
  if (port == 80 || port == 443) {
    port = "";
  } else {
    port = `:${port}`
  }
  let localDocroot = `${location.protocol}//${location.hostname}${port}/docroot/pennyblack/${version}`;
  // Use the window load event to keep the page load performant
  window.addEventListener('load', function () {
    navigator.serviceWorker.register(`${localDocroot}/dist/service-worker.js`, {scope: "/"}
    ).then(function (registration) {
      console.debug('[Workbox] Service worker registration successful, scope is:', registration.scope);
    }).catch(function (error) {
      console.log('[Workbox] Service worker registration failed, error:', error.stack);
    });
  });
}
